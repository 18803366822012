import {
   FormControl,
   TextField,
   Grid,
   Button,
   InputLabel,
   Select,
   MenuItem,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import uk from 'date-fns/locale/en-GB';
import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HomeworkTable } from './homework-table';
import { useHttp } from '../../hooks/use-http';
import { alertActions } from '../../store/slices/alert';
import { Class } from '../../types';
import { URL_SERVER_CLASS } from '../../util/constants';
import LoadingWrapper from '../shared/loadingWrapper';

export const HomeworkDateSelection = () => {
   const [selectedDate, setSelectedDate] = useState(new Date());
   const [isLoading, setIsLoading] = useState(true);
   const [showHomeworks, setShowHomeworks] = useState(false);

   const [teachers, setTeachers] = useState([]);
   const [selectedTeacher, setSelectedTeacher] = useState();

   const authenticatedUser = useSelector(state => state.auth.user);

   const [classes, setClasses] = useState([]);
   const [selectedClass, setSelectedClass] = useState();
   const durations = [
      { name: 'One Day', value: 1 },
      { name: 'One Week', value: 7 },
      { name: 'Two Week', value: 14 },
   ];
   const [selectedDuration, setSelectedDuration] = useState(1);
   const accessToken = useSelector(state => state.auth.accessToken);
   const dispatch = useDispatch();

   const { get } = useHttp();

   useEffect(() => {
      const getTeachersData = async () => {
         const response = await get('/api/teacher', undefined, accessToken);
         const authenticatedTeacherIdx = response.findIndex(
            ({ teacher_email }) => teacher_email === authenticatedUser.email
         );

         if (authenticatedTeacherIdx !== -1) {
            const temp = response[authenticatedTeacherIdx];
            response[authenticatedTeacherIdx] = response[0];
            response[0] = temp;
         }

         setTeachers([{ id: -1, teacher_name: 'All Teachers' }, ...response]);
         setSelectedTeacher(-1);
      };

      getTeachersData();
   }, []);

   useEffect(() => {
      const getClassesData = async () => {
         try {
            const classes = await get(URL_SERVER_CLASS, undefined, accessToken);
            setClasses(classes);
            setSelectedClass(classes[0][Class.classId]);
         } catch (e) {
            dispatch(alertActions.setAlert(e));
         }
      };

      getClassesData();
   }, []);

   useEffect(() => {
      setIsLoading(!selectedClass);
   }, [selectedClass]);

   return (
      <LoadingWrapper isLoading={isLoading}>
         <Grid alignContent="center" container alignItems="center" spacing={2}>
            <Grid item sm={6} xs={12}>
               <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={uk}>
                     <DatePicker
                        label="Date"
                        value={selectedDate}
                        disableFuture
                        onChange={newDate => setSelectedDate(newDate)}
                        renderInput={params => <TextField size="small" {...params} />}
                     />
                  </LocalizationProvider>
               </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
               <FormControl fullWidth>
                  <InputLabel size="small" id="marathon-classes">
                     Classes
                  </InputLabel>
                  <Select
                     size="small"
                     labelId="marathon-classes"
                     value={selectedClass}
                     label="Classes"
                     onChange={event => setSelectedClass(event.target.value)}
                  >
                     {classes.map(mClass => (
                        <MenuItem
                           key={mClass[Class.classId]}
                           value={mClass[Class.classId]}
                        >
                           {mClass[Class.className]}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
               <FormControl fullWidth>
                  <InputLabel size="small" id="marathon-duration">
                     Duration
                  </InputLabel>
                  <Select
                     size="small"
                     labelId="duration"
                     value={selectedDuration}
                     label="Duration"
                     onChange={event => setSelectedDuration(event.target.value)}
                  >
                     {durations.map(duration => (
                        <MenuItem key={duration.value} value={duration.value}>
                           {duration.name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
               <FormControl fullWidth>
                  <InputLabel size="small" id="marathon-duration">
                     Teacher
                  </InputLabel>
                  <Select
                     size="small"
                     labelId="duration"
                     value={selectedTeacher}
                     label="Duration"
                     onChange={event => setSelectedTeacher(event.target.value)}
                  >
                     {teachers.map(teacher => (
                        <MenuItem key={teacher.id} value={teacher.id}>
                           {teacher.teacher_name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Grid>
            <Grid item xs={12}>
               <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setShowHomeworks(true)}
                  disableElevation
               >
                  Show Homeworks
               </Button>
            </Grid>
         </Grid>
         {showHomeworks && (
            <Fragment>
               <br />
               <HomeworkTable
                  selectedDuration={selectedDuration}
                  selectedClass={selectedClass}
                  selectedDate={selectedDate}
                  selectedTeacher={selectedTeacher}
               />
            </Fragment>
         )}
      </LoadingWrapper>
   );
};
