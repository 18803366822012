import RedeemIcon from '@mui/icons-material/Redeem';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { IconButton } from '@mui/material';

export const ColumnCode = {
   reg: 'reg',
   l: 'l',
   w: 'w',
   d1: 'd1',
   e: 'e',
   u: 'u',
   name: 'name',
   month: 'month',
   good: 'good',
   excellent: 'excellent',
   shouldGetPresent: 'shouldGetPresent',
};

export const CellContentTemplate = {
   tooltip: 'tooltip',
   behaviorCodeSwitch: 'behaviorCodeSwitch',
   behaviorCodeTooltip: 'behaviorCodeTooltip',
};

export const ColumnConfiguration = {
   [ColumnCode.name]: {
      flex: 4,
      highlight: false,
      code: ColumnCode.name,
      headerContent: ColumnCode.name.toLocaleUpperCase(),
      headerToolTip: 'Name',
      cellType: CellContentTemplate.tooltip,
   },
   [ColumnCode.month]: {
      flex: 5,
      highlight: false,
      code: ColumnCode.month,
      headerContent: ColumnCode.month.toLocaleUpperCase(),
      headerToolTip: 'Month',
      cellType: CellContentTemplate.tooltip,
   },
   [ColumnCode.reg]: {
      code: ColumnCode.reg,
      headerContent: ColumnCode.reg.toLocaleUpperCase(),
      headerToolTip: 'Registration',
      graphBgColor: 'rgba(64, 24, 158, 0.2)',
      graphBorderColor: 'rgba(64, 24, 158)',
      cellType: CellContentTemplate.behaviorCodeSwitch,
   },
   [ColumnCode.l]: {
      code: ColumnCode.l,
      headerToolTip: 'Late',
      graphBgColor: 'rgba(54, 162, 235, 0.2)',
      graphBorderColor: 'rgba(54, 162, 235)',
   },
   [ColumnCode.w]: {
      code: ColumnCode.w,
      headerToolTip: 'Warning',
      graphBgColor: 'rgba(138, 158, 24, 0.2)',
      graphBorderColor: 'rgba(138, 158, 24)',
   },
   [ColumnCode.d1]: {
      code: ColumnCode.d1,
      headerToolTip: 'Short Detention',
      graphBgColor: 'rgba(24, 158, 154, 0.2)',
      graphBorderColor: 'rgba(24, 158, 154)',
   },
   [ColumnCode.e]: {
      code: ColumnCode.e,
      headerToolTip: 'Equipment',
      graphBgColor: 'rgba(138, 158, 24, 0.2)',
      graphBorderColor: 'rgba(138, 158, 24)',
      cellType: CellContentTemplate.behaviorCodeSwitch,
   },
   [ColumnCode.u]: {
      code: ColumnCode.u,
      headerToolTip: 'Uniform',
      graphBgColor: 'rgba(158, 133, 24, 0.2)',
      graphBorderColor: 'rgba(158, 133, 24)',
      cellType: CellContentTemplate.behaviorCodeSwitch,
   },
   [ColumnCode.good]: {
      code: ColumnCode.good,
      headerContent: onClick => (
         <IconButton onClick={onClick} sx={{ p: 0 }} size="small">
            <ThumbUpOffAltIcon fontSize="inherit" sx={{ fontSize: 17, color: 'black' }} />
         </IconButton>
      ),
      headerToolTip: 'Classwork completed',
      headerHighlight: true,
      graphBgColor: 'rgba(158, 24, 24, 0.2)',
      graphBorderColor: 'rgba(158, 24, 24)',
      cellType: CellContentTemplate.behaviorCodeSwitch,
   },
   [ColumnCode.excellent]: {
      code: ColumnCode.excellent,
      headerContent: onClick => (
         <IconButton onClick={onClick} sx={{ p: 0 }} size="small">
            <ThumbUpAltIcon sx={{ fontSize: 17, color: 'black' }} />
         </IconButton>
      ),
      headerToolTip: 'Excellent',
      headerHighlight: true,
      graphBgColor: 'rgba(64, 24, 158, 0.2)',
      graphBorderColor: 'rgba(64, 24, 158)',
      cellType: CellContentTemplate.behaviorCodeSwitch,
   },
   [ColumnCode.shouldGetPresent]: {
      code: ColumnCode.shouldGetPresent,
      headerContent: onClick => (
         <IconButton onClick={onClick} sx={{ p: 0 }} size="small">
            <RedeemIcon sx={{ fontSize: 17, color: 'black' }} />
         </IconButton>
      ),
      headerToolTip: 'Should Get Present',
      headerHighlight: true,
      graphBgColor: 'rgba(54, 162, 235, 0.2)',
      graphBorderColor: 'rgba(54, 162, 235)',
      cellType: CellContentTemplate.behaviorCodeSwitch,
   },
   default: {
      flex: 2,
      title: '',
      color: 'primary',
      highlight: true,
      headerHighlight: false,
      graphBgColor: 'rgba(54, 162, 235, 0.2)',
      graphBorderColor: 'rgba(54, 162, 235)',
      headerContent: code => code.toLocaleUpperCase(),
      cellType: CellContentTemplate.behaviorCodeTooltip,
   },
};

export const EvaluationCodes = [
   ColumnCode.good,
   ColumnCode.excellent,
   ColumnCode.shouldGetPresent,
];

export const BehaviorCodes = [
   ColumnCode.reg,
   ColumnCode.e,
   ColumnCode.u,
   ColumnCode.l,
   ColumnCode.w,
   ColumnCode.d1,
   ...EvaluationCodes,
];

export const GraphCodes = BehaviorCodes;
export const GraphCodeColumns = GraphCodes.map(code => ColumnConfiguration[code]);

export const EntranceCodes = [ColumnCode.name, ...BehaviorCodes];
export const EntranceCodeColumns = EntranceCodes.map(code => ColumnConfiguration[code]);

export const DailyStatCodes = [ColumnCode.name, ...BehaviorCodes];
export const DailyStatColumns = DailyStatCodes.map(code => ColumnConfiguration[code]);

export const MonthlyStatCodes = [ColumnCode.month, ...BehaviorCodes];
export const MonthlyStatColumns = MonthlyStatCodes.map(code => ColumnConfiguration[code]);
