import { useTheme } from '@material-ui/core/styles';
import { Grid, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';

const BehaviorCodeCell = ({ flex, highlight, children }) => {
   const theme = useTheme();
   const [isShownInPrimary, setIsShownInPrimary] = useState(false);

   return (
      <Grid
         p={0.2}
         xs={flex}
         item={true}
         onMouseEnter={() => setIsShownInPrimary(true)}
         onMouseLeave={() => setIsShownInPrimary(false)}
      >
         <Box
            sx={{
               height: '100%',
               border: `1px solid ${
                  highlight && isShownInPrimary ? theme.palette.primary.main : grey[400]
               }`,
            }}
            borderRadius={1}
         >
            <Box py={1}>{children}</Box>
         </Box>
      </Grid>
   );
};

export default BehaviorCodeCell;
