import { Grid, Box } from '@mui/material';
import { Fragment } from 'react';
import { getGraphData, GraphCodeColumns } from './behavior-monthly-stats-code-columns';
import BarChart from '../../shared/bar-chart';

export const BehaviorMonthlyStatsGraphs = ({ monthlyBehaviorResults }) => {
   const graphsJSX =
      monthlyBehaviorResults.length > 0 ? (
         <Grid justifyContent={'center'} container columns={16}>
            {GraphCodeColumns.map(columnConfig => {
               return (
                  <Grid item key={columnConfig.code} md={8} xs={16}>
                     <Box padding={3}>
                        <BarChart
                           {...getGraphData(monthlyBehaviorResults, columnConfig)}
                        ></BarChart>
                     </Box>
                  </Grid>
               );
            })}
         </Grid>
      ) : (
         []
      );

   return <Fragment>{graphsJSX}</Fragment>;
};
