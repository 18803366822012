import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { TextField, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

const BehaviorNote = ({ updateNote, note, rowCount }) => {
   const [newNote, setNewNote] = useState('');
   const [isInNoteEditMode, setIsInNoteEditMode] = useState(false);

   useEffect(() => setNewNote(note), [note]);

   return (
      <Stack sx={{ marginTop: 3, width: '100%' }} variant="outlined">
         <TextField
            label="Notes"
            multiline
            fullWidth
            rows={rowCount}
            disabled={!isInNoteEditMode}
            onChange={event => setNewNote(event.target.value)}
            value={newNote}
            variant="filled"
         />
         <Button
            fullWidth
            sx={{ borderRadius: 0 }}
            onClick={() => {
               if (isInNoteEditMode) updateNote(newNote);
               setIsInNoteEditMode(prev => !prev);
            }}
            variant="contained"
            disableElevation
         >
            {isInNoteEditMode ? <SaveIcon /> : <EditIcon />}
         </Button>

         {isInNoteEditMode && (
            <Button
               sx={{ borderRadius: 0 }}
               fullWidth
               onClick={() => {
                  setNewNote(note);
                  setIsInNoteEditMode(prev => !prev);
               }}
               variant="contained"
               color="error"
               disableElevation
            >
               <DoDisturbIcon />
            </Button>
         )}
      </Stack>
   );
};

export default BehaviorNote;
