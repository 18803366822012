import {
   FormControl,
   TextField,
   Grid,
   Button,
   InputLabel,
   Select,
   MenuItem,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import uk from 'date-fns/locale/en-GB';
import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NoteTable } from './note-table';
import { useHttp } from '../../hooks/use-http';
import { alertActions } from '../../store/slices/alert';
import { Class } from '../../types';
import { URL_SERVER_CLASS } from '../../util/constants';
import LoadingWrapper from '../shared/loadingWrapper';

export const NoteDateSelection = () => {
   const [selectedDate, setSelectedDate] = useState(new Date());
   const [isLoading, setIsLoading] = useState(true);
   const [showNotes, setShowNotes] = useState(false);

   const [classes, setClasses] = useState([]);
   const [selectedClass, setSelectedClass] = useState();
   const accessToken = useSelector(state => state.auth.accessToken);
   const dispatch = useDispatch();

   const { get } = useHttp();

   const getClassesData = async () => {
      try {
         const classes = await get(URL_SERVER_CLASS, undefined, accessToken);
         setClasses(classes);
         setSelectedClass(classes[0][Class.classId]);
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   useEffect(() => {
      getClassesData();
   }, []);

   useEffect(() => {
      setIsLoading(!selectedClass);
   }, [selectedClass]);

   return (
      <LoadingWrapper isLoading={isLoading}>
         <Grid alignContent="center" container alignItems="center" spacing={2}>
            <Grid item sm={6} xs={12}>
               <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={uk}>
                     <DatePicker
                        label="Date"
                        value={selectedDate}
                        disableFuture
                        onChange={newDate => setSelectedDate(newDate)}
                        renderInput={params => <TextField size="small" {...params} />}
                     />
                  </LocalizationProvider>
               </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
               <FormControl fullWidth>
                  <InputLabel size="small" id="marathon-classes">
                     Classes
                  </InputLabel>
                  <Select
                     size="small"
                     labelId="marathon-classes"
                     value={selectedClass}
                     label="Classes"
                     onChange={event => setSelectedClass(event.target.value)}
                  >
                     {classes.map(mClass => (
                        <MenuItem
                           key={mClass[Class.classId]}
                           value={mClass[Class.classId]}
                        >
                           {mClass[Class.className]}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Grid>
            <Grid item xs={12}>
               <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setShowNotes(true)}
                  disableElevation
               >
                  Show Notes
               </Button>
            </Grid>
         </Grid>
         {showNotes && (
            <Fragment>
               <br />
               <NoteTable selectedClass={selectedClass} selectedDate={selectedDate} />
            </Fragment>
         )}
      </LoadingWrapper>
   );
};
