import { Card } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Redirect, useRouteMatch } from 'react-router-dom';
import StudentsInClass from './students-in-class';
import TransitionRoute from '../shared/transition-route';

const Student = () => {
   const match = useRouteMatch();
   return (
      <Card sx={{ textAlign: 'center', my: 5, border: 1, borderColor: grey[300], p: 3 }}>
         <TransitionRoute path={`${match.path}`}>
            <StudentsInClass />
         </TransitionRoute>
         <TransitionRoute path="/student/*">
            <Redirect to={`${match.path}`} />
         </TransitionRoute>
      </Card>
   );
};

export default Student;
