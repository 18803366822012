import { Bar } from 'react-chartjs-2';

const BarChart = ({ title, data, labels, borderColor, backgroundColor }) => {
   const chartData = {
      labels,
      datasets: [
         {
            label: title.toUpperCase(),
            data,
            backgroundColor: [backgroundColor],
            borderColor: [borderColor],
            borderWidth: 2,
         },
      ],
   };

   return <Bar data={chartData}></Bar>;
};

export default BarChart;
