import { Grid, Box, Tooltip } from '@mui/material';
import { Fragment } from 'react';
import {
   BehaviorMonthlyStatsColumnConfiguration,
   MonthlyStatColumns,
} from './behavior-monthly-stats-code-columns';
import BehaviorEntranceCell from '../behavior-entrance-cell';

export const BehaviorMonthlyStudentStatsTable = ({ monthlyBehaviorResults }) => {
   const generateCodeHeaders = (begin, end) => {
      const filteredColumns = [
         MonthlyStatColumns[0],
         ...MonthlyStatColumns.slice(begin, end),
      ];

      return filteredColumns.map(columnConfig => {
         const cellAttributes = {
            flex:
               columnConfig.flex ?? BehaviorMonthlyStatsColumnConfiguration.default.flex,
            highlight: false,
         };

         const content =
            columnConfig.headerContent ??
            BehaviorMonthlyStatsColumnConfiguration.default.headerContent(
               columnConfig.code
            );

         return (
            <BehaviorEntranceCell key={columnConfig.code} {...cellAttributes}>
               <Tooltip arrow title={columnConfig.headerToolTip}>
                  <Box>{content}</Box>
               </Tooltip>
            </BehaviorEntranceCell>
         );
      });
   };

   const generateCodeEntries = (begin, end) => {
      const filteredColumns = [
         MonthlyStatColumns[0],
         ...MonthlyStatColumns.slice(begin, end),
      ];

      return monthlyBehaviorResults.map(monthlyBehaviorResult =>
         filteredColumns.map(columnConfig => {
            const cellAttributes = {
               flex:
                  columnConfig.flex ??
                  BehaviorMonthlyStatsColumnConfiguration.default.flex,
            };

            return (
               <BehaviorEntranceCell
                  key={`${monthlyBehaviorResult.month}-${columnConfig.code}`}
                  {...cellAttributes}
               >
                  <Box>
                     {columnConfig.code === 'reg'
                        ? `${parseInt(
                             Math.round(monthlyBehaviorResult[columnConfig.code] * 100)
                          )}%`
                        : monthlyBehaviorResult[columnConfig.code]}
                  </Box>
               </BehaviorEntranceCell>
            );
         })
      );
   };

   const headerJSX = generateCodeHeaders(1, 11);
   const entriesJSX = generateCodeEntries(1, 11);

   const header0JSX = generateCodeHeaders(1, 4);
   const header1JSX = generateCodeHeaders(4, 7);
   const header2JSX = generateCodeHeaders(7, 11);

   const entries0JSX = generateCodeEntries(1, 4);
   const entries1JSX = generateCodeEntries(4, 7);
   const entries2JSX = generateCodeEntries(7, 11);

   return (
      <Fragment>
         {monthlyBehaviorResults.length > 0 && (
            <Box>
               <Grid container sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Grid
                     textAlign="center"
                     container
                     columns={22}
                     marginY={1}
                     spacing={0.5}
                  >
                     {headerJSX}
                     {entriesJSX}
                  </Grid>
               </Grid>

               <Grid container sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <Grid
                     textAlign="center"
                     container
                     columns={10}
                     marginY={1}
                     spacing={0.5}
                  >
                     {header0JSX}
                     {entries0JSX}
                  </Grid>

                  <Grid
                     textAlign="center"
                     container
                     columns={10}
                     marginY={3}
                     spacing={0.5}
                  >
                     {header1JSX}
                     {entries1JSX}
                  </Grid>

                  <Grid
                     textAlign="center"
                     container
                     columns={10}
                     marginY={1}
                     spacing={0.5}
                  >
                     {header2JSX}
                     {entries2JSX}
                  </Grid>
               </Grid>
            </Box>
         )}
      </Fragment>
   );
};
