import { Card } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import BehaviorDateSelection from './behavior-date-selection';
import { BehaviorMonthlyStudentStats } from './monthly-stats/behavior-monthly-student-stats';
import TransitionRoute from '../shared/transition-route';

const Behavior = () => {
   const match = useRouteMatch();
   return (
      <Card sx={{ textAlign: 'center', my: 5, border: 1, borderColor: grey[300], p: 3 }}>
         <Switch>
            <TransitionRoute path={`${match.path}/behavior-date-selection`}>
               <BehaviorDateSelection />
            </TransitionRoute>
            <TransitionRoute path={`${match.path}/behavior-student-stats/:studentId`}>
               <BehaviorMonthlyStudentStats />
            </TransitionRoute>
            <TransitionRoute path="/behavior/*">
               <Redirect to={`${match.path}/behavior-date-selection`} />
            </TransitionRoute>
         </Switch>
      </Card>
   );
};

export default Behavior;
