import { Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadingWrapper from '../shared/loadingWrapper';

export const NoteTable = ({ selectedDate, selectedClass }) => {
   const [isLoading, setIsLoading] = useState(true);
   const [lecturesWithNotes, setLecturesWithNotes] = useState([]);
   const accessToken = useSelector(state => state.auth.accessToken);

   const getLectures = async () => {
      setIsLoading(true);
      const response = await axios.get('/api/lecture/notes', {
         params: {
            date: selectedDate,
            selectedClass,
         },
         headers: {
            Authorization: `Bearer ${accessToken}`,
         },
      });
      setLecturesWithNotes(response.data.data);
      setIsLoading(false);
   };

   useEffect(() => {
      if (!selectedDate || !selectedClass) return;
      getLectures();
   }, [selectedDate, selectedClass]);

   return (
      <LoadingWrapper isLoading={isLoading}>
         <Stack sx={{ px: 1 }} spacing={1.5}>
            {lecturesWithNotes.length > 0 &&
               lecturesWithNotes.map(({ lecture, teacher }) => (
                  <Box key={lecture._id}>
                     <Typography color="primary" variant="subtitle1">
                        <strong>{lecture.period}</strong>{' '}
                     </Typography>
                     <Divider />
                     <Typography variant="body2">
                        <strong>Teacher:</strong> {teacher.teacher_name}
                     </Typography>
                     <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2">
                        <strong>Note:</strong> {lecture.note}
                     </Typography>
                  </Box>
               ))}

            {lecturesWithNotes.length == 0 && (
               <Box textAlign="center">
                  <Divider />
                  <br />
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="8%"
                     fill="currentColor"
                     className="bi bi-x-lg"
                     viewBox="0 0 16 16"
                  >
                     <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                  <Typography>No Notes</Typography>
               </Box>
            )}
         </Stack>
      </LoadingWrapper>
   );
};
