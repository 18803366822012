import { Box, CircularProgress } from '@mui/material';
import { Fragment } from 'react';

const BehaviorDateSelection = ({ isLoading, children }) => {
   return (
      <Fragment>
         {!isLoading && <div>{children}</div>}
         {isLoading && (
            <Box textAlign="center">
               <CircularProgress />
            </Box>
         )}
      </Fragment>
   );
};

export default BehaviorDateSelection;
