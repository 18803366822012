export const months = [
   'September',
   'October',
   'November',
   'December',
   'January',
   'February',
   'March',
   'April',
   'May',
   'June',
   'July',
   'August',
];

export const DAILY_OVERALL_DATA = 'Daily Overall Data';

export const ROLE_TEACHER = 'Teacher';

export const URL_CLIENT_AUTH = '/auth';
export const URL_CLIENT_AUTH_SIGN_IN = `${URL_CLIENT_AUTH}/sign-in`;
export const URL_CLIENT_AUTH_FORGOT_PASSWORD = `${URL_CLIENT_AUTH}/forgot-password`;
export const URL_CLIENT_AUTH_UPDATE_PASSWORD = `${URL_CLIENT_AUTH}/update-password`;

export const URL_CLIENT_STUDENT = '/student';

export const URL_CLIENT_BEHAVIOR = '/behavior';
export const URL_CLIENT_BEHAVIOR_DATE_SELECTION = `${URL_CLIENT_BEHAVIOR}/behavior-date-selection`;

const SERVER_BASE_URL = '';
const SERVER_API = `${SERVER_BASE_URL}/api`;

export const URL_SERVER_AUTH = `${SERVER_API}/auth`;
export const URL_SERVER_GOOGLE_AUTH_URL = `${URL_SERVER_AUTH}/google/authURL`;
export const URL_SERVER_GOOGLE_SIGN_IN = `${URL_SERVER_AUTH}/google/sign-in`;

export const URL_CLIENT_HOMEWORK = '/homework';

export const URL_SERVER_CLASS = `${SERVER_API}/class`;
export const URL_SERVER_CLASS_PERIODS = `${SERVER_API}/class/periods`;

export const URL_SERVER_STUDENT = `${SERVER_API}/student`;
export const URL_SERVER_BEHAVIOR = `${SERVER_API}/behavior`;
export const URL_SERVER_MONTHLY_STUDENT_BEHAVIOR_STATS = `${URL_SERVER_BEHAVIOR}/monthly-student-stats`;
export const URL_SERVER_DAILY_CLASS_STUDENTS_BEHAVIOR_DATA = `${URL_SERVER_BEHAVIOR}/daily-class-student-data`;
export const URL_SERVER_DAILY_BEHAVIOR_STATS = `${URL_SERVER_BEHAVIOR}/daily-student-stats`;

export const URL_SERVER_LECTURE = `${SERVER_API}/lecture`;
export const URL_SERVER_ANNUAL_NOTE = `${SERVER_API}/annualNote`;
export const URL_SERVER_CHECK_LECTURE = `${URL_SERVER_LECTURE}/check-lecture`;
