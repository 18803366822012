import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import {
   List,
   ListItemButton,
   ListItemText,
   ListItem,
   Select,
   MenuItem,
   InputLabel,
   Stack,
   FormControl,
   OutlinedInput,
   Divider,
   InputAdornment,
   Typography,
   Grid,
} from '@mui/material';

import { grey } from '@mui/material/colors';
import axios from 'axios';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useHttp } from '../../hooks/use-http';
import { alertActions } from '../../store/slices/alert';
import { Class } from '../../types';
import { URL_SERVER_CLASS, URL_SERVER_STUDENT } from '../../util/constants';
import LoadingWrapper from '../shared/loadingWrapper';

const StudentsInClass = () => {
   const { get } = useHttp();
   const history = useHistory();
   const dispatch = useDispatch();

   const [classes, setClasses] = useState([]);
   const [students, setStudents] = useState([]);
   const [academicYears, setAcademicYears] = useState([]);
   const [selectedAcademicYear, setSelectedAcademicYear] = useState();
   const [filteredStudents, setFilteredStudents] = useState([]);

   const [isLoading, setLoading] = useState(true);
   const [areStudentsLoading, setAreStudentsLoading] = useState(false);

   const [filterQuery, setFilterQuery] = useState('');
   const [selectedClass, setSelectedClass] = useState();
   const accessToken = useSelector(state => state.auth.accessToken);

   const getAcademicsYearData = async () => {
      const response = await axios.get('/api/lecture/academics-year', {
         headers: {
            Authorization: `Bearer ${accessToken}`,
         },
      });
      setAcademicYears(response.data.data);
      setSelectedAcademicYear(response.data.data[0].id);
   };

   const getClassesData = async () => {
      try {
         const classes = await get(URL_SERVER_CLASS, undefined, accessToken);
         setClasses(classes);
         setSelectedClass(classes[0][Class.classId]);
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   const getStudentsData = async () => {
      if (!selectedClass) return;
      setAreStudentsLoading(true);
      const data = await get(
         URL_SERVER_STUDENT,
         { classId: selectedClass, academicsId: selectedAcademicYear },
         accessToken
      );
      setStudents(data);
      setAreStudentsLoading(false);
      setLoading(false);
   };

   useEffect(() => {
      getAcademicsYearData();
      getClassesData();
   }, []);

   useEffect(() => {
      if (!selectedClass || !selectedAcademicYear) return;
      getStudentsData();
   }, [selectedClass, selectedAcademicYear]);

   useEffect(() => {
      if (filterQuery.length < 3) {
         setFilteredStudents(students);
      } else {
         setFilteredStudents(
            students.filter(student => student.student_name.includes(filterQuery))
         );
      }
   }, [filterQuery, students]);

   return (
      <LoadingWrapper isLoading={isLoading}>
         <Stack spacing={2}>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                     <InputLabel id="marathon-academic-year">Academic Year</InputLabel>
                     <Select
                        labelId="marathon-academic-year"
                        value={selectedAcademicYear}
                        label="Academic Year"
                        size="small"
                        onChange={event => setSelectedAcademicYear(event.target.value)}
                     >
                        {academicYears.map(academicYear => (
                           <MenuItem key={academicYear.id} value={academicYear.id}>
                              {academicYear.title}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </Grid>

               <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                     <InputLabel id="marathon-classes">Classes</InputLabel>
                     <Select
                        labelId="marathon-classes"
                        value={selectedClass}
                        label="Classes"
                        size="small"
                        onChange={event => setSelectedClass(event.target.value)}
                     >
                        {classes.map(mClass => (
                           <MenuItem
                              key={mClass[Class.classId]}
                              value={mClass[Class.classId]}
                           >
                              {mClass[Class.className]}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </Grid>
            </Grid>

            <FormControl fullWidth>
               <InputLabel htmlFor="input-search">Search</InputLabel>
               <OutlinedInput
                  id="input-search"
                  size="small"
                  onChange={event =>
                     setFilterQuery(event.target.value.toLocaleUpperCase())
                  }
                  startAdornment={
                     <InputAdornment position="start">
                        <SearchIcon />
                     </InputAdornment>
                  }
                  label="Search"
               />
            </FormControl>

            <LoadingWrapper isLoading={areStudentsLoading}>
               {filteredStudents.length !== 0 && (
                  <div>
                     <Divider />
                     <List disablePadding>
                        {filteredStudents.map(student => (
                           <ListItem key={student.id} disablePadding>
                              <ListItemButton
                                 onClick={() =>
                                    history.push(
                                       `/behavior/behavior-student-stats/${student.id}?academicId=${selectedAcademicYear}`
                                    )
                                 }
                              >
                                 <ListItemText
                                    secondary={student.id}
                                    primary={student.student_name}
                                 />
                              </ListItemButton>
                           </ListItem>
                        ))}
                     </List>
                  </div>
               )}
               {filteredStudents.length === 0 && (
                  <div>
                     <Divider />
                     <ClearIcon sx={{ fontSize: 70, color: grey[400] }} />
                     <Typography variant="subtitle2" color={grey[600]}>
                        No Students Found
                     </Typography>
                  </div>
               )}
            </LoadingWrapper>
         </Stack>
      </LoadingWrapper>
   );
};

export default StudentsInClass;
