import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import Auth from './components/auth/auth';
import Behavior from './components/behavior/behavior';
import { Homework } from './components/homework/homework';
import { Note } from './components/note/note';
import Alert from './components/shared/alert';
import AppBar from './components/shared/appbar';
import TransitionRoute from './components/shared/transition-route';
import Student from './components/students/student';

import {
   URL_CLIENT_AUTH,
   URL_CLIENT_AUTH_SIGN_IN,
   URL_CLIENT_BEHAVIOR,
   URL_CLIENT_STUDENT,
   URL_CLIENT_BEHAVIOR_DATE_SELECTION,
   URL_CLIENT_HOMEWORK,
} from './util/constants';

function App() {
   const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
   return (
      <div>
         <header>
            <AppBar />
         </header>

         <main>
            <Alert></Alert>
            <Container>
               <Switch>
                  {isLoggedIn && (
                     <TransitionRoute path={URL_CLIENT_BEHAVIOR}>
                        <Behavior />
                     </TransitionRoute>
                  )}

                  {isLoggedIn && (
                     <TransitionRoute path={URL_CLIENT_STUDENT}>
                        <Student />
                     </TransitionRoute>
                  )}

                  {isLoggedIn && (
                     <TransitionRoute path={URL_CLIENT_HOMEWORK}>
                        <Homework />
                     </TransitionRoute>
                  )}

                  {isLoggedIn && (
                     <TransitionRoute path={'/note'}>
                        <Note />
                     </TransitionRoute>
                  )}

                  {!isLoggedIn && (
                     <TransitionRoute path={URL_CLIENT_AUTH}>
                        <Auth />
                     </TransitionRoute>
                  )}

                  <TransitionRoute path="*">
                     <Redirect
                        to={
                           isLoggedIn
                              ? URL_CLIENT_BEHAVIOR_DATE_SELECTION
                              : URL_CLIENT_AUTH_SIGN_IN
                        }
                     />
                  </TransitionRoute>
               </Switch>
            </Container>
         </main>
      </div>
   );
}

export default App;
