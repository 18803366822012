import {
   Grid,
   Stack,
   Typography,
   Divider,
   Box,
   Button,
   Dialog,
   DialogContent,
   DialogActions,
} from '@mui/material';

import { Fragment, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getGraphData, GraphCodeColumns } from './behavior-monthly-stats-code-columns';
import { BehaviorMonthlyStudentStatsTable } from './behavior-monthly-stats-table';
import BarChart from '../../shared/bar-chart';

export const BehaviorMonthlyStatsPrintDialog = ({
   openDialog,
   setOpenDialog,
   student,
   annualNote,
   selectedPeriods,
   selectedAcademicYear,
   monthlyBehaviorResults,
}) => {
   const componentRef = useRef();
   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   });

   const graphsPrintJSX = GraphCodeColumns.map(columnConfig => {
      return (
         <Fragment key={columnConfig.code}>
            <Grid alignContent={'center'} item xs={16}>
               <Box sx={{ width: 600 }} padding={3}>
                  <BarChart
                     {...getGraphData(monthlyBehaviorResults, columnConfig)}
                  ></BarChart>
                  <br />
               </Box>
            </Grid>
         </Fragment>
      );
   });

   return (
      <Dialog
         fullWidth={true}
         maxWidth={'xl'}
         open={openDialog}
         onClose={() => setOpenDialog(false)}
      >
         <DialogContent ref={componentRef}>
            <Stack container spacing={1}>
               <Grid item>
                  <strong>Name:</strong> {student && student.student_name}
               </Grid>
               <Grid item>
                  <strong>Class:</strong> {student && student.mClass.class_name}
               </Grid>
               <Grid item>
                  <strong>Academic Year:</strong>{' '}
                  {selectedAcademicYear && selectedAcademicYear.title}
               </Grid>
            </Stack>
            <Divider sx={{ marginY: 1 }} />
            <Stack spacing={0.5}>
               <strong>Selected Periods</strong>
               {selectedPeriods.map(period => (
                  <Box key={period}>
                     <strong>{'>'}</strong> {period}
                  </Box>
               ))}
            </Stack>
            <br />
            <br />
            <BehaviorMonthlyStudentStatsTable
               monthlyBehaviorResults={monthlyBehaviorResults}
            />
            <br />
            <br />
            <Grid container columns={16}>
               {graphsPrintJSX}
            </Grid>
            <Divider sx={{ marginY: 1 }} />
            <br />
            <br />
            <Typography variant="h6">
               <strong>Notes</strong>
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>{annualNote.note}</Typography>
         </DialogContent>
         <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
            <Button onClick={handlePrint}>Print</Button>
         </DialogActions>
      </Dialog>
   );
};
