import RedeemIcon from '@mui/icons-material/Redeem';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { months } from '../../../util/constants';

export const ColumnCode = {
   reg: 'reg',
   l: 'l',
   w: 'w',
   d1: 'd1',
   e: 'e',
   u: 'u',
   name: 'name',
   month: 'month',
   good: 'good',
   excellent: 'excellent',
   shouldGetPresent: 'shouldGetPresent',
};

export const BehaviorMonthlyStatsColumnConfiguration = {
   [ColumnCode.month]: {
      flex: 4,
      code: ColumnCode.month,
      headerToolTip: 'Month',
   },
   [ColumnCode.reg]: {
      code: ColumnCode.reg,
      headerToolTip: 'Registration',
      graphBgColor: 'rgba(64, 24, 158, 0.2)',
      graphBorderColor: 'rgba(64, 24, 158)',
   },
   [ColumnCode.l]: {
      code: ColumnCode.l,
      headerToolTip: 'Late',
      graphBgColor: 'rgba(54, 162, 235, 0.2)',
      graphBorderColor: 'rgba(54, 162, 235)',
   },
   [ColumnCode.w]: {
      code: ColumnCode.w,
      headerToolTip: 'Warning',
      graphBgColor: 'rgba(138, 158, 24, 0.2)',
      graphBorderColor: 'rgba(138, 158, 24)',
   },
   [ColumnCode.d1]: {
      code: ColumnCode.d1,
      headerToolTip: 'Short Detention',
      graphBgColor: 'rgba(24, 158, 154, 0.2)',
      graphBorderColor: 'rgba(24, 158, 154)',
   },
   [ColumnCode.e]: {
      flex: 2,
      code: ColumnCode.e,
      headerToolTip: 'Equipment',
      graphBgColor: 'rgba(138, 158, 24, 0.2)',
      graphBorderColor: 'rgba(138, 158, 24)',
   },
   [ColumnCode.u]: {
      code: ColumnCode.u,
      headerToolTip: 'Uniform',
      graphBgColor: 'rgba(158, 133, 24, 0.2)',
      graphBorderColor: 'rgba(158, 133, 24)',
   },
   [ColumnCode.good]: {
      code: ColumnCode.good,
      headerContent: (
         <ThumbUpOffAltIcon fontSize="inherit" sx={{ fontSize: 17, color: 'black' }} />
      ),
      headerToolTip: 'Classwork completed',
      graphBgColor: 'rgba(158, 24, 24, 0.2)',
      graphBorderColor: 'rgba(158, 24, 24)',
   },
   [ColumnCode.excellent]: {
      code: ColumnCode.excellent,
      headerContent: <ThumbUpAltIcon sx={{ fontSize: 17, color: 'black' }} />,
      headerToolTip: 'Excellent',
      graphBgColor: 'rgba(64, 24, 158, 0.2)',
      graphBorderColor: 'rgba(64, 24, 158)',
   },
   [ColumnCode.shouldGetPresent]: {
      code: ColumnCode.shouldGetPresent,
      headerContent: <RedeemIcon sx={{ fontSize: 17, color: 'black' }} />,
      headerToolTip: 'Should Get Present',
      graphBgColor: 'rgba(54, 162, 235, 0.2)',
      graphBorderColor: 'rgba(54, 162, 235)',
   },
   default: {
      flex: 2,
      title: '',
      color: 'primary',
      graphBgColor: 'rgba(54, 162, 235, 0.2)',
      graphBorderColor: 'rgba(54, 162, 235)',
      headerContent: code => code.toLocaleUpperCase(),
   },
};

export const EvaluationCodes = [
   ColumnCode.good,
   ColumnCode.excellent,
   ColumnCode.shouldGetPresent,
];

export const BehaviorCodes = [
   ColumnCode.reg,
   ColumnCode.e,
   ColumnCode.l,
   ColumnCode.w,
   ColumnCode.d1,
   ColumnCode.u,
   ...EvaluationCodes,
];

export const GraphCodes = BehaviorCodes;
export const GraphCodeColumns = GraphCodes.map(
   code => BehaviorMonthlyStatsColumnConfiguration[code]
);

export const EntranceCodes = [ColumnCode.name, ...BehaviorCodes];
export const EntranceCodeColumns = EntranceCodes.map(
   code => BehaviorMonthlyStatsColumnConfiguration[code]
);

export const DailyStatCodes = [ColumnCode.name, ...BehaviorCodes];
export const DailyStatColumns = DailyStatCodes.map(
   code => BehaviorMonthlyStatsColumnConfiguration[code]
);

export const MonthlyStatCodes = [ColumnCode.month, ...BehaviorCodes];
export const MonthlyStatColumns = MonthlyStatCodes.map(
   code => BehaviorMonthlyStatsColumnConfiguration[code]
);

export const getGraphData = (monthlyBehaviorResults, columnConfig) => ({
   labels: months,
   data: monthlyBehaviorResults.map(monthlyBehaviorResult =>
      columnConfig.code === 'reg'
         ? parseInt(Math.round(monthlyBehaviorResult[columnConfig.code] * 100))
         : monthlyBehaviorResult[columnConfig.code]
   ),
   title: columnConfig.code === 'good' ? 'Classwork completed' : columnConfig.code,
   backgroundColor:
      columnConfig.graphBgColor ??
      BehaviorMonthlyStatsColumnConfiguration.default.graphBgColor,
   borderColor:
      columnConfig.graphBorderColor ??
      BehaviorMonthlyStatsColumnConfiguration.default.graphBorderColor,
});
