import { grey } from '@material-ui/core/colors';

import {
   Typography,
   Grid,
   Switch,
   Tooltip,
   Alert,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   Box,
} from '@mui/material';

import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BehaviorEntranceCell from './behavior-entrance-cell';
import BehaviorHomework from './behavior-homework';
import BehaviorNote from './behavior-note';
import BehaviorRegSwitch from './behavior-reg-switch';
import { useHttp } from '../../hooks/use-http';
import { alertActions } from '../../store/slices/alert';

import {
   ColumnConfiguration,
   EntranceCodeColumns,
   CellContentTemplate,
   EvaluationCodes,
   ColumnCode,
} from '../../util/code-columns';

import {
   URL_SERVER_LECTURE,
   URL_SERVER_BEHAVIOR,
   URL_SERVER_CHECK_LECTURE,
   URL_SERVER_DAILY_CLASS_STUDENTS_BEHAVIOR_DATA,
} from '../../util/constants';

import LoadingWrapper from '../shared/loadingWrapper';

const BehaviorEntrance = ({
   showBehaviors,
   setShowBehaviors,
   selectedClass,
   selectedDate,
   selectedPeriod,
}) => {
   const { post, get } = useHttp();
   const dispatch = useDispatch();
   const [lecture, setLecture] = useState();
   const [behaviors, setBehaviors] = useState([]);
   const [teachers, setTeachers] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const accessToken = useSelector(state => state.auth.accessToken);
   const authenticatedUser = useSelector(state => state.auth.user);

   useEffect(() => {
      getTeachersData();
   }, []);

   useEffect(
      () => getBehaviorsData(),
      [selectedClass, selectedDate, selectedPeriod, showBehaviors]
   );

   const getTeachersData = async () => {
      const response = await get('/api/teacher', undefined, accessToken);
      const authenticatedTeacherIdx = response.findIndex(
         ({ teacher_email }) => teacher_email === authenticatedUser.email
      );

      if (authenticatedTeacherIdx !== -1) {
         const temp = response[authenticatedTeacherIdx];
         response[authenticatedTeacherIdx] = response[0];
         response[0] = temp;
      }

      setTeachers(response);
   };

   const getBehaviorsData = async () => {
      if (!showBehaviors) return;
      setIsLoading(true);

      try {
         const { lecture, behaviors } = await post(
            URL_SERVER_DAILY_CLASS_STUDENTS_BEHAVIOR_DATA,
            {
               period: selectedPeriod,
               classId: `${selectedClass}`,
               date: selectedDate,
            },
            accessToken
         );

         setLecture(lecture);
         setBehaviors(behaviors);
      } catch (e) {
         dispatch(alertActions.setAlert(e));
         setShowBehaviors(false);
      }

      setIsLoading(false);
   };

   const updateBehavior = async (behavior, code, newValue) => {
      if (newValue < 0) return;

      const behaviorCodes = EvaluationCodes.includes(code)
         ? EvaluationCodes.reduce(
              (a, c) => ({ ...a, [c]: c === code ? newValue : 0 }),
              {}
           )
         : { [code]: newValue };

      try {
         const updatedBehavior = await post(
            URL_SERVER_BEHAVIOR,
            {
               ...behaviorCodes,
               behaviorId: behavior._id,
            },
            accessToken
         );

         const updatedBehaviors = behaviors.map(behaviorData =>
            behaviorData.behavior._id === updatedBehavior._id
               ? { student: behaviorData.student, behavior: updatedBehavior }
               : behaviorData
         );

         setBehaviors(updatedBehaviors);
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   const onUpdateTeacher = async newTeacher => {
      try {
         const data = await post(
            URL_SERVER_LECTURE,
            {
               note: lecture.note,
               teacher: newTeacher,
               homework: lecture.homework,
               lectureId: lecture._id,
            },
            accessToken
         );

         setLecture(data);
         dispatch(
            alertActions.setAlert({
               message: 'Teacher successfully updated!',
               status: 'success',
            })
         );
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   const onUpdateNote = async newNote => {
      try {
         const data = await post(
            URL_SERVER_LECTURE,
            {
               note: newNote,
               teacher: lecture.teacher,
               homework: lecture.homework,
               lectureId: lecture._id,
            },
            accessToken
         );

         setLecture(data);
         dispatch(
            alertActions.setAlert({
               message: 'Note successfully updated!',
               status: 'success',
            })
         );
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   const onUpdateHomework = async newHomework => {
      try {
         const data = await post(
            URL_SERVER_LECTURE,
            {
               homework: newHomework,
               note: lecture.note,
               teacher: lecture.teacher,
               lectureId: lecture._id,
            },
            accessToken
         );
         setLecture(data);
         dispatch(
            alertActions.setAlert({
               message: 'Homework successfully updated!',
               status: 'success',
            })
         );
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   const onCheckLecture = async event => {
      const isChecked = event.target.checked;

      if (isChecked && !lecture.teacher) {
         dispatch(
            alertActions.setAlert({
               message: 'Teacher field can not be empty.',
               status: 'error',
            })
         );
         return;
      }

      try {
         await post(
            URL_SERVER_CHECK_LECTURE,
            { isChecked, lectureId: lecture._id },
            accessToken
         );
         setLecture(prev => ({ ...prev, isChecked }));
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   const onSwitchChange = (behavior, code, check) =>
      updateBehavior(behavior, code, check ? 1 : 0);

   const updateBulkSwitch = async code => {
      if (!EvaluationCodes.includes(code)) return;
      const isCodeAllChecked = behaviors.every(({ behavior }) => behavior[code] === 1);
      const target = isCodeAllChecked ? 0 : 1;

      const behaviorCodes = EvaluationCodes.reduce(
         (a, c) => ({ ...a, [c]: c === code ? target : 0 }),
         {}
      );

      const updatedBehaviors = behaviors.map(async ({ behavior, student }) => ({
         student,
         behavior: await post(
            URL_SERVER_BEHAVIOR,
            {
               ...behaviorCodes,
               behaviorId: behavior._id,
            },
            accessToken
         ),
      }));

      setBehaviors(await Promise.all(updatedBehaviors));
   };

   const generateCodesHeader = (begin, end) => {
      const filteredCodeColumns = [
         EntranceCodeColumns[0],
         ...EntranceCodeColumns.slice(begin, end),
      ];

      return filteredCodeColumns.map(columnConfig => {
         const cellAttributes = {
            flex: columnConfig.flex ?? ColumnConfiguration.default.flex,
            highlight:
               columnConfig.headerHighlight ??
               ColumnConfiguration.default.headerHighlight,
         };

         let content =
            columnConfig.headerContent ??
            ColumnConfiguration.default.headerContent(columnConfig.code);

         if (columnConfig.cellType === CellContentTemplate.behaviorCodeSwitch) {
            if (columnConfig.code === ColumnCode.good) {
               content = columnConfig.headerContent(() =>
                  updateBulkSwitch(ColumnCode.good)
               );
            } else if (columnConfig.code === ColumnCode.excellent) {
               content = columnConfig.headerContent(() =>
                  updateBulkSwitch(ColumnCode.excellent)
               );
            } else if (columnConfig.code === ColumnCode.shouldGetPresent) {
               content = columnConfig.headerContent(() =>
                  updateBulkSwitch(ColumnCode.shouldGetPresent)
               );
            }
         }

         return (
            <BehaviorEntranceCell key={columnConfig.code} {...cellAttributes}>
               <Tooltip arrow title={columnConfig.headerToolTip}>
                  <div>{content}</div>
               </Tooltip>
            </BehaviorEntranceCell>
         );
      });
   };

   const generateEntries = (begin, end) => {
      const filteredColumns = [
         EntranceCodeColumns[0],
         ...EntranceCodeColumns.slice(begin, end),
      ];

      return behaviors.map(({ behavior, student }) =>
         filteredColumns.map(columnConfig => {
            const cellAttributes = {
               flex: columnConfig.flex ?? ColumnConfiguration.default.flex,
               highlight: columnConfig.highlight ?? ColumnConfiguration.default.highlight,
            };

            const contentAttributes = {
               behavior,
               onSwitchChange,
               code: columnConfig.code,
               color: columnConfig.color ?? ColumnConfiguration.default.color,
               title: columnConfig.title ?? ColumnConfiguration.default.title,
            };

            const cellType =
               columnConfig.cellType ?? ColumnConfiguration.default.cellType;

            return (
               <BehaviorEntranceCell
                  key={`${student.id}-${columnConfig.code}`}
                  {...cellAttributes}
               >
                  {cellType === CellContentTemplate.behaviorCodeSwitch && (
                     <BehaviorRegSwitch {...contentAttributes} />
                  )}
                  {cellType === CellContentTemplate.behaviorCodeTooltip && (
                     <input
                        style={{
                           width: '80%',
                           height: '100%',
                           fontSize: '16px',
                           textAlign: 'center',
                           border: '0px',
                           outline: 'none',
                        }}
                        type="number"
                        value={contentAttributes.behavior[contentAttributes.code]}
                        onChange={e =>
                           updateBehavior(
                              contentAttributes.behavior,
                              contentAttributes.code,
                              e.target.value === '' ? 0 : e.target.value
                           )
                        }
                     />
                  )}
                  {cellType === CellContentTemplate.tooltip && (
                     <Tooltip {...contentAttributes}>
                        <div>{student.student_name}</div>
                     </Tooltip>
                  )}
               </BehaviorEntranceCell>
            );
         })
      );
   };

   const codesHeaderJSX = generateCodesHeader(1, 11);
   const codesHeader0JSX = generateCodesHeader(1, 4);
   const codesHeader1JSX = generateCodesHeader(4, 7);
   const codesHeader2JSX = generateCodesHeader(7, 11);

   const codesEntriesJSX = generateEntries(1, 11);
   const codesEntries0JSX = generateEntries(1, 4);
   const codesEntries1JSX = generateEntries(4, 7);
   const codesEntries2JSX = generateEntries(7, 11);

   return (
      <LoadingWrapper isLoading={isLoading}>
         {behaviors.length !== 0 && (
            <Fragment>
               <Alert
                  sx={{ my: 1 }}
                  severity={lecture.isChecked ? 'success' : 'error'}
                  action={
                     <Switch
                        color="success"
                        onClick={event => onCheckLecture(event)}
                        checked={lecture.isChecked}
                     />
                  }
               >
                  {lecture.isChecked ? (
                     <Typography variant="body1">This class has been checked.</Typography>
                  ) : (
                     <Typography variant="body1">
                        Please <strong>check</strong> the switch to confirm you entered
                        the grades, otherwise, the grades will be{' '}
                        <strong>invalid!</strong>
                     </Typography>
                  )}
               </Alert>

               <FormControl sx={{ my: 2 }} fullWidth>
                  <InputLabel size="small" id="marathon-periods">
                     Teacher
                  </InputLabel>
                  <Select
                     size="small"
                     labelId="marathon-periods"
                     value={lecture.teacher}
                     label="Teacher"
                     onChange={event => onUpdateTeacher(event.target.value)}
                  >
                     {teachers.map(teacher => (
                        <MenuItem
                           style={{
                              backgroundColor:
                                 teacher.teacher_email === authenticatedUser.email
                                    ? grey[100]
                                    : '',
                           }}
                           key={teacher.id}
                           value={teacher.id}
                        >
                           {teacher.teacher_name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>

               <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  <Grid container columns={22}>
                     {codesHeaderJSX}
                  </Grid>
                  <Grid columns={22} container>
                     {codesEntriesJSX}
                  </Grid>
               </Box>
               <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                  <Grid container columns={10}>
                     {codesHeader0JSX}
                  </Grid>

                  <Grid columns={10} container>
                     {codesEntries0JSX}
                  </Grid>

                  <br />
                  <br />

                  <Grid container columns={10}>
                     {codesHeader1JSX}
                  </Grid>

                  <Grid columns={10} container>
                     {codesEntries1JSX}
                  </Grid>

                  <br />
                  <br />

                  <Grid container columns={10}>
                     {codesHeader2JSX}
                  </Grid>

                  <Grid columns={10} container>
                     {codesEntries2JSX}
                  </Grid>
               </Box>

               <Grid container>
                  <BehaviorHomework
                     updateHomework={onUpdateHomework}
                     homework={lecture.homework}
                  />
               </Grid>
               <Grid container>
                  <BehaviorNote
                     updateNote={onUpdateNote}
                     note={lecture.note}
                     rowCount={7}
                  />
               </Grid>
            </Fragment>
         )}
      </LoadingWrapper>
   );
};

export default BehaviorEntrance;
