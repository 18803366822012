import { configureStore } from '@reduxjs/toolkit';
import alertSlice from './slices/alert';
import authSlice from './slices/auth';

const store = configureStore({
   reducer: {
      auth: authSlice.reducer,
      alert: alertSlice.reducer,
   },
   middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
         serializableCheck: false,
      }),
});

export default store;
