import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
   name: 'auth',
   initialState: {
      user: JSON.parse(localStorage.getItem('user')) || null,
      accessToken: localStorage.getItem('accessToken') || null,
      isLoggedIn: !!(localStorage.getItem('accessToken') || null),
   },
   reducers: {
      signIn(state, action) {
         const { staff, token } = action.payload;
         state.user = staff;
         state.accessToken = token;
         state.isLoggedIn = true;
         localStorage.setItem('user', JSON.stringify(state.user));
         localStorage.setItem('accessToken', state.accessToken);
      },
      signOut(state) {
         state.user = null;
         state.accessToken = null;
         state.isLoggedIn = false;
         localStorage.removeItem('user');
         localStorage.removeItem('accessToken');
      },
   },
});

export const authActions = authSlice.actions;
export default authSlice;
