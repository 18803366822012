import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack, Typography, IconButton, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { BehaviorMonthlyStatsGraphs } from './behavior-monthly-stats-graphs';
import { BehaviorMonthlyStatsIntro } from './behavior-monthly-stats-intro';
import { BehaviorMonthlyStudentsStatsNote } from './behavior-monthly-stats-note';
import { BehaviorMonthlyStatsPrintDialog } from './behavior-monthly-stats-print-dialog';
import { BehaviorMonthlyStudentStatsTable } from './behavior-monthly-stats-table';

export const BehaviorMonthlyStudentStats = () => {
   const [monthlyBehaviorResults, setMonthlyBehaviorResults] = useState([]);
   const [annualNote, setAnnualNote] = useState('');
   const [student, setStudent] = useState();
   const [selectedPeriods, setSelectedPeriods] = useState([]);
   const [selectedAcademicYear, setSelectedAcademicYear] = useState();
   const [openDialog, setOpenDialog] = useState(false);

   return (
      <Fragment>
         <BehaviorMonthlyStatsPrintDialog
            monthlyBehaviorResults={monthlyBehaviorResults}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            student={student}
            annualNote={annualNote}
            selectedPeriods={selectedPeriods}
            selectedAcademicYear={selectedAcademicYear}
         />

         <Stack textAlign={'start'}>
            <Stack alignItems="center" direction="row">
               <Link style={{ textDecoration: 'none', color: grey[600] }} to="/student">
                  <IconButton>
                     <ArrowBackIcon />
                  </IconButton>
               </Link>

               <Typography fontWeight={700} variant="h6" component="h6">
                  Student Statistics
               </Typography>
            </Stack>
            <Divider></Divider>
            <br />
            <BehaviorMonthlyStatsIntro
               setOpenDialog={setOpenDialog}
               setMonthlyBehaviorResults={setMonthlyBehaviorResults}
               student={student}
               setStudent={setStudent}
               selectedPeriods={selectedPeriods}
               setSelectedPeriods={setSelectedPeriods}
               selectedAcademicYear={selectedAcademicYear}
               setSelectedAcademicYear={setSelectedAcademicYear}
            />

            {monthlyBehaviorResults.length > 0 && (
               <Fragment>
                  <br />
                  <Divider />
                  <BehaviorMonthlyStudentStatsTable
                     monthlyBehaviorResults={monthlyBehaviorResults}
                  />
                  <Divider />
                  <br />

                  <BehaviorMonthlyStatsGraphs
                     monthlyBehaviorResults={monthlyBehaviorResults}
                  />

                  <BehaviorMonthlyStudentsStatsNote
                     student={student}
                     annualNote={annualNote}
                     setAnnualNote={setAnnualNote}
                     selectedAcademicYear={selectedAcademicYear}
                     monthlyBehaviorResults={monthlyBehaviorResults}
                  />
               </Fragment>
            )}
         </Stack>
      </Fragment>
   );
};
