import Grow from '@mui/material/Fade';
import { Route } from 'react-router-dom';

const TransitionRoute = ({ path, timeout, children }) => {
   return (
      <Route key={path} path={path}>
         {({ match }) => (
            <Grow timeout={timeout ?? 500} in={match != null}>
               <div>{children}</div>
            </Grow>
         )}
      </Route>
   );
};

export default TransitionRoute;
