import axios from 'axios';

export const useHttp = () => {
   const post = async (url, body, accessToken) => {
      try {
         const response = await axios.post(url, body, {
            headers: {
               Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            },
         });

         return response.data.data;
      } catch (error) {
         const alert = { status: 'error', message: JSON.stringify(error) };

         if (error.response) {
            const errors = error.response.data.errors;
            alert.message = errors.join(', ');
         }

         throw alert;
      }
   };

   const get = async (url, params, accessToken) => {
      try {
         const response = await axios.get(url, {
            params,
            headers: {
               Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            },
         });

         return response.data.data;
      } catch (error) {
         const alert = { status: 'error', message: JSON.stringify(error) };

         if (error.response) {
            const errors = error.response.data.errors;
            alert.message = errors.join(', ');
         }

         throw alert;
      }
   };

   return { get, post };
};
