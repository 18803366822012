import { Box } from '@mui/material';
import { Fragment } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import AuthGoogle from './auth-google';
import AuthSignIn from './auth-sign-in';
import TransitionRoute from '../shared/transition-route';

const Auth = () => {
   const match = useRouteMatch();

   return (
      <Fragment>
         <Box textAlign="center" margin={5}>
            <img
               src="/logo-big.png"
               style={{ 'max-width': '300px', width: '100%' }}
               alt=""
            />
         </Box>
         <Switch>
            <TransitionRoute path={`${match.path}/sign-in`}>
               <AuthSignIn />
            </TransitionRoute>
            <TransitionRoute path={`${match.path}/google`}>
               <AuthGoogle />
            </TransitionRoute>
            <TransitionRoute path={`${match.path}/*`}>
               <Redirect to={`${match.path}/sign-in`} />
            </TransitionRoute>
         </Switch>
      </Fragment>
   );
};

export default Auth;
