import { Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadingWrapper from '../shared/loadingWrapper';

export const HomeworkTable = ({
   selectedDate,
   selectedDuration,
   selectedTeacher,
   selectedClass,
}) => {
   const [isLoading, setIsLoading] = useState(true);
   const [lecturesWithHomeworks, setLecturesWithHomeworks] = useState([]);
   const accessToken = useSelector(state => state.auth.accessToken);

   useEffect(() => {
      const getLectures = async () => {
         setIsLoading(true);
         const response = await axios.get('/api/lecture/homeworks', {
            params: {
               date: selectedDate,
               selectedClass,
               duration: selectedDuration,
               teacher: selectedTeacher === -1 ? undefined : selectedTeacher,
            },
            headers: {
               Authorization: `Bearer ${accessToken}`,
            },
         });
         setLecturesWithHomeworks(response.data.data);
         setIsLoading(false);
      };

      if (!selectedDate || !selectedClass) return;
      getLectures();
   }, [selectedDate, selectedDuration, selectedTeacher, selectedClass]);

   const dateSet = new Set();

   return (
      <LoadingWrapper isLoading={isLoading}>
         <Stack sx={{ px: 1 }} spacing={1.5}>
            {lecturesWithHomeworks.length > 0 &&
               lecturesWithHomeworks.map(({ lecture, teacher }) => {
                  const isPrinted = dateSet.has(lecture.date);

                  if (!isPrinted) {
                     dateSet.add(lecture.date);
                  }

                  return (
                     <>
                        {!isPrinted && (
                           <Box>
                              <br />
                              <Typography color="primary" variant="h6">
                                 <strong>
                                    {new Date(lecture.date).toLocaleDateString()}
                                 </strong>
                              </Typography>{' '}
                              <hr />
                           </Box>
                        )}
                        <Box key={lecture._id}>
                           <Typography color="primary" variant="subtitle1">
                              <strong>{lecture.period}</strong>{' '}
                           </Typography>
                           <Divider />
                           <Typography variant="body2">
                              <strong>Teacher:</strong> {teacher.teacher_name}
                           </Typography>
                           <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2">
                              <strong>Homework:</strong> {lecture.homework}
                           </Typography>
                        </Box>
                     </>
                  );
               })}

            {lecturesWithHomeworks.length == 0 && (
               <Box textAlign="center">
                  <Divider />
                  <br />
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="8%"
                     fill="currentColor"
                     className="bi bi-x-lg"
                     viewBox="0 0 16 16"
                  >
                     <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                  <Typography>No Homeworks</Typography>
               </Box>
            )}
         </Stack>
      </LoadingWrapper>
   );
};
