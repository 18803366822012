import { Grid, Tooltip, Alert, Stack, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BehaviorEntranceCell from './behavior-entrance-cell';
import { useHttp } from '../../hooks/use-http';
import { alertActions } from '../../store/slices/alert';

import {
   CellContentTemplate,
   ColumnCode,
   ColumnConfiguration,
   DailyStatColumns,
} from '../../util/code-columns';

import { URL_SERVER_DAILY_BEHAVIOR_STATS } from '../../util/constants';
import LoadingWrapper from '../shared/loadingWrapper';

const BehaviorDailyStats = ({
   showBehaviors,
   setShowBehaviors,
   selectedClass,
   selectedDate,
   selectedPeriod,
   periods,
}) => {
   const { post } = useHttp();
   const dispatch = useDispatch();

   const [behaviorStats, setBehaviorStats] = useState([]);
   const [checkedCount, setCheckedCount] = useState(0);
   const [checkedLectures, setCheckedLectures] = useState([]);
   const [unCheckedCount, setUnCheckedCount] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const accessToken = useSelector(state => state.auth.accessToken);
   const uncheckedPeriods = periods.filter(period => !checkedLectures.map(l => l.period).includes(period) && period !== 'Daily Overall Data');

   useEffect(
      () => getDailyBehaviorStatsData(),
      [selectedClass, selectedDate, selectedPeriod, showBehaviors]
   );

   const getDailyBehaviorStatsData = async () => {
      if (!showBehaviors) return;
      setIsLoading(true);

      try {
         const { stats, checkedLectures, checkedCount, unCheckedCount } = await post(
            URL_SERVER_DAILY_BEHAVIOR_STATS,
            { classId: `${selectedClass}`, date: selectedDate },
            accessToken
         );

         setCheckedLectures(checkedLectures);
         setCheckedCount(checkedCount);
         setUnCheckedCount(unCheckedCount);
         setBehaviorStats(stats);
      } catch (e) {
         dispatch(alertActions.setAlert(e));
         setShowBehaviors(false);
      }

      setIsLoading(false);
   };

   const codesHeaderJSX = DailyStatColumns.map(columnConfig => {
      const cellAttributes = {
         flex: columnConfig.flex ?? ColumnConfiguration.default.flex,
         highlight:
            columnConfig.headerHighlight ?? ColumnConfiguration.default.headerHighlight,
      };

      if (typeof columnConfig.headerContent === 'function') {
         return (
            <BehaviorEntranceCell key={columnConfig.code} {...cellAttributes}>
               <Tooltip arrow title={columnConfig.headerToolTip}>
                  <div>
                     {columnConfig.headerContent() ??
                        ColumnConfiguration.default.headerContent(columnConfig.code)}
                  </div>
               </Tooltip>
            </BehaviorEntranceCell>
         );
      }

      return (
         <BehaviorEntranceCell key={columnConfig.code} {...cellAttributes}>
            <Tooltip arrow title={columnConfig.headerToolTip}>
               <div>
                  {columnConfig.headerContent ??
                     ColumnConfiguration.default.headerContent(columnConfig.code)}
               </div>
            </Tooltip>
         </BehaviorEntranceCell>
      );
   });

   const codesEntriesJSX = behaviorStats.map(({ stat, student }) =>
      DailyStatColumns.map(columnConfig => {
         const cellAttributes = {
            flex: columnConfig.flex ?? ColumnConfiguration.default.flex,
            highlight: columnConfig.highlight ?? ColumnConfiguration.default.highlight,
         };

         const contentAttributes = {
            code: columnConfig.code,
            color: columnConfig.color ?? ColumnConfiguration.default.color,
            title: columnConfig.title ?? ColumnConfiguration.default.title,
         };

         let content = '';

         if (columnConfig.code === ColumnCode.name) {
            content = student.student_name;
         } else if (columnConfig.code === ColumnCode.reg) {
            content = `${parseInt(Math.round(stat[columnConfig.code] * 100))}%`;
         } else {
            content = stat[columnConfig.code];
         }

         return (
            <BehaviorEntranceCell
               key={`${student.id}-${columnConfig.code}`}
               {...cellAttributes}
            >
               <Tooltip {...contentAttributes}>
                  <div>{content}</div>
               </Tooltip>
            </BehaviorEntranceCell>
         );
      })
   );

   return (
      <LoadingWrapper isLoading={isLoading}>
         {behaviorStats.length !== 0 && (
            <Fragment>
              <Alert severity="success">
                           Checked: <strong>{checkedCount}</strong>
                        </Alert>
               <br />
                  <Grid container spacing={2} direction={'row'}>
                     {checkedLectures.map(lecture => <Grid item key={lecture.period}><Box padding={1} borderColor={grey[400]} border={1} key={lecture.period}>{lecture.period}</Box></Grid>)}
                  </Grid>
                  <br />
                  <Alert severity="error">
                           Unchecked: <strong>{unCheckedCount}</strong>
                        </Alert>
                     <br />
                  <Grid container spacing={2}  direction={'row'}>
                     {uncheckedPeriods.map(period => <Grid item key={period}><Box padding={1} borderColor={grey[400]} border={1} >{period}</Box></Grid>)}
                  </Grid>
               <br />
               <Grid container columns={22}>
                  {codesHeaderJSX}
               </Grid>
               <Grid columns={22} container>
                  {codesEntriesJSX}
               </Grid>
            </Fragment>
         )}
      </LoadingWrapper>
   );
};

export default BehaviorDailyStats;
