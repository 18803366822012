import GoogleIcon from '@mui/icons-material/Google';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useHttp } from '../../hooks/use-http';
import { alertActions } from '../../store/slices/alert';
import { URL_SERVER_GOOGLE_AUTH_URL } from '../../util/constants';

const AuthSignIn = () => {
   const [isLoading, setIsLoading] = useState(false);
   const dispatch = useDispatch();
   const { get } = useHttp();

   const onClickSignIn = async () => {
      setIsLoading(true);
      try {
         window.location.href = await get(URL_SERVER_GOOGLE_AUTH_URL);
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
      setIsLoading(false);
   };

   return (
      <Box textAlign="center" margin={5}>
         <LoadingButton
            onClick={onClickSignIn}
            sx={{ padding: 2 }}
            color="error"
            loading={isLoading}
            variant="contained"
            loadingPosition="start"
            startIcon={<GoogleIcon />}
         >
            Login
         </LoadingButton>
      </Box>
   );
};

export default AuthSignIn;
