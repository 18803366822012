import {
   Box,
   Stack,
   Button,
   Select,
   MenuItem,
   TextField,
   Typography,
   InputLabel,
   FormControl,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import uk from 'date-fns/locale/en-GB';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BehaviorDailyStats from './behavior-daily-stats';
import BehaviorEntrance from './behavior-entrance';

import { useHttp } from '../../hooks/use-http';
import { alertActions } from '../../store/slices/alert';
import { Class } from '../../types';

import {
   DAILY_OVERALL_DATA,
   URL_SERVER_CLASS,
   URL_SERVER_CLASS_PERIODS,
} from '../../util/constants';

import LoadingWrapper from '../shared/loadingWrapper';

const BehaviorDateSelection = () => {
   const { get } = useHttp();
   const dispatch = useDispatch();

   const [classes, setClasses] = useState([]);
   const [periods, setPeriods] = useState([]);

   const [isLoading, setIsLoading] = useState(true);
   const [showBehaviors, setShowBehaviors] = useState(false);

   const [selectedClass, setSelectedClass] = useState();
   const [selectedPeriod, setSelectedPeriod] = useState();
   const [selectedDate, setSelectedDate] = useState(new Date());

   const accessToken = useSelector(state => state.auth.accessToken);

   const getClassesData = async () => {
      try {
         const classes = await get(URL_SERVER_CLASS, undefined, accessToken);
         setClasses(classes);
         setSelectedClass(classes[0][Class.classId]);
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   const getPeriodsData = async () => {
      try {
         const periods = [
            ...(await get(URL_SERVER_CLASS_PERIODS, undefined, accessToken)),
            DAILY_OVERALL_DATA,
         ];

         setPeriods(periods);
         setSelectedPeriod(periods[0]);
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   useEffect(() => {
      getClassesData();
      getPeriodsData();
   }, []);

   useEffect(() => {
      setIsLoading(!(selectedClass && selectedPeriod));
   }, [selectedClass, selectedPeriod]);

   const onSubmitForm = async event => {
      event.preventDefault();
      setShowBehaviors(true);
   };

   return (
      <LoadingWrapper isLoading={isLoading}>
         <div>
            <form onSubmit={onSubmitForm}>
               <Stack spacing={2}>
                  <FormControl fullWidth>
                     <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={uk}
                     >
                        <DatePicker
                           label="Date"
                           value={selectedDate}
                           disableFuture
                           onChange={newDate => setSelectedDate(newDate)}
                           renderInput={params => <TextField size="small" {...params} />}
                        />
                     </LocalizationProvider>
                  </FormControl>
                  <Stack direction="row" spacing={3}>
                     <FormControl fullWidth>
                        <InputLabel size="small" id="marathon-classes">
                           Classes
                        </InputLabel>
                        <Select
                           size="small"
                           labelId="marathon-classes"
                           value={selectedClass}
                           label="Classes"
                           onChange={event => setSelectedClass(event.target.value)}
                        >
                           {classes.map(mClass => (
                              <MenuItem
                                 key={mClass[Class.classId]}
                                 value={mClass[Class.classId]}
                              >
                                 {mClass[Class.className]}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>

                     <FormControl fullWidth>
                        <InputLabel size="small" id="marathon-periods">
                           Periods
                        </InputLabel>
                        <Select
                           size="small"
                           labelId="marathon-periods"
                           value={selectedPeriod}
                           label="Periods"
                           onChange={event => setSelectedPeriod(event.target.value)}
                        >
                           {periods.map(period => (
                              <MenuItem key={period} value={period}>
                                 {period}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  </Stack>
                  <FormControl fullWidth>
                     <Button disableElevation type="submit" variant="contained">
                        <Typography fontWeight={500}>Enter Behavior</Typography>
                     </Button>
                  </FormControl>
               </Stack>
            </form>

            {selectedPeriod !== DAILY_OVERALL_DATA && showBehaviors && (
               <Box marginTop={2}>
                  <BehaviorEntrance
                     showBehaviors={showBehaviors}
                     setShowBehaviors={setShowBehaviors}
                     selectedDate={selectedDate}
                     selectedPeriod={selectedPeriod}
                     selectedClass={selectedClass}
                  />
               </Box>
            )}
            {selectedPeriod === DAILY_OVERALL_DATA &&
               showBehaviors &&
               classes.map(mClass => (
                  <Box key={mClass} marginTop={2}>
                     <br />
                     <Typography textAlign="start" variant="h6" component="h6">
                        <strong>{mClass[Class.className]}</strong>
                     </Typography>
                     <hr />
                     <BehaviorDailyStats
                        showBehaviors={showBehaviors}
                        periods={periods}
                        setShowBehaviors={setShowBehaviors}
                        selectedDate={selectedDate}
                        selectedPeriod={selectedPeriod}
                        selectedClass={mClass[Class.classId]}
                     />
                     <br />
                  </Box>
               ))}
         </div>
      </LoadingWrapper>
   );
};

export default BehaviorDateSelection;
