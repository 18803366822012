import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../../store/slices/alert';
import Note from '../../behavior/behavior-note';

export const BehaviorMonthlyStudentsStatsNote = ({
   student,
   annualNote,
   setAnnualNote,
   selectedAcademicYear,
   monthlyBehaviorResults,
}) => {
   const dispatch = useDispatch();
   const accessToken = useSelector(state => state.auth.accessToken);

   useEffect(() => {
      if (monthlyBehaviorResults.length) {
         getAnnualNote();
      }
   }, [monthlyBehaviorResults]);

   const getAnnualNote = async () => {
      const response = await axios.get('/api/annualNote', {
         headers: {
            Authorization: `Bearer ${accessToken}`,
         },
         params: {
            year: selectedAcademicYear.year,
            student: student.id,
         },
      });

      setAnnualNote(response.data.data);
   };

   const onUpdateNote = async newNote => {
      try {
         await axios.post(
            '/api/annualNote',
            { note: newNote, student: student.id, year: selectedAcademicYear.year },
            {
               headers: {
                  Authorization: `Bearer ${accessToken}`,
               },
            }
         );

         await getAnnualNote();

         dispatch(
            alertActions.setAlert({
               message: 'Note successfully updated!',
               status: 'success',
            })
         );
      } catch (e) {
         dispatch(alertActions.setAlert(e));
      }
   };

   return <Note updateNote={onUpdateNote} note={annualNote.note} rowCount={20} />;
};
