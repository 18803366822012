import { Card } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Switch } from 'react-router-dom';
import { HomeworkDateSelection } from './homework-date-selection';

export const Homework = () => {
   return (
      <Card
         sx={{
            my: 5,
            border: 1,
            borderColor: grey[300],
            p: 3,
         }}
      >
         <Switch>
            <HomeworkDateSelection />
         </Switch>
      </Card>
   );
};
