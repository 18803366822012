import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHttp } from '../../hooks/use-http';
import { alertActions } from '../../store/slices/alert';
import { authActions } from '../../store/slices/auth';
import { URL_SERVER_GOOGLE_SIGN_IN } from '../../util/constants';

const AuthGoogle = () => {
   const { search } = useLocation();
   const dispatch = useDispatch();
   const { post } = useHttp();
   const queryParams = new URLSearchParams(search);
   const code = queryParams.get('code');

   const signIn = async () => {
      try {
         console.log(code);
         const data = await post(URL_SERVER_GOOGLE_SIGN_IN, { code });
         console.log(data);
         dispatch(authActions.signIn(data));
         dispatch(
            alertActions.setAlert({
               message: 'Welcome',
               status: 'success',
            })
         );
      } catch (e) {
         alertActions.setAlert(e);
      }
   };

   useEffect(() => signIn(), []);

   return (
      <Box textAlign="center" margin={5}>
         <CircularProgress size={80} />
      </Box>
   );
};

export default AuthGoogle;
