import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

import {
   Grid,
   Stack,
   Typography,
   Box,
   InputLabel,
   Select,
   FormControl,
   MenuItem,
   FormGroup,
   FormControlLabel,
   Checkbox,
   Button,
   Divider,
} from '@mui/material';

import axios from 'axios';
import { useEffect, useState, useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import LoadingWrapper from '../../shared/loadingWrapper';

function useQuery() {
   const { search } = useLocation();
   return useMemo(() => new URLSearchParams(search), [search]);
}

export const BehaviorMonthlyStatsIntro = ({
   setMonthlyBehaviorResults,
   setOpenDialog,
   student,
   setStudent,
   selectedPeriods,
   setSelectedPeriods,
   selectedAcademicYear,
   setSelectedAcademicYear,
}) => {
   const match = useRouteMatch();
   const { studentId } = match.params;
   const accessToken = useSelector(state => state.auth.accessToken);
   const [academicYears, setAcademicYears] = useState([]);
   const [periods, setPeriods] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [isStudentFoundInAcademicYear, setIsStudentFoundInAcademicYear] = useState(true);
   const query = useQuery();

   const getMonthlyStudentBehaviorsData = async () => {
      setIsLoading(true);
      setMonthlyBehaviorResults([]);

      try {
         const response = await axios.post(
            `/api/behavior/monthly-student-stats/${studentId}`,
            { periods: selectedPeriods },
            {
               headers: {
                  Authorization: `Bearer ${accessToken}`,
               },
               params: {
                  academicId: selectedAcademicYear.id,
               },
            }
         );

         const data = response.data.data;
         setStudent(data.student);
         setMonthlyBehaviorResults(data.monthlyBehaviorResults);
         setIsStudentFoundInAcademicYear(true);
      } catch (e) {
         setIsStudentFoundInAcademicYear(false);
      }
      setIsLoading(false);
   };

   const getAcademicsYearData = async () => {
      const response = await axios.get('/api/lecture/academics-year', {
         headers: {
            Authorization: `Bearer ${accessToken}`,
         },
      });
      const academicYears = response.data.data;
      const academicId = query.get('academicId');
      const selected = academicYears.find(ay => ay.id === academicId);
      setAcademicYears(academicYears);
      setSelectedAcademicYear(selected);
   };

   const getPeriodsData = async () => {
      const response = await axios.get('/api/class/periods', {
         headers: {
            Authorization: `Bearer ${accessToken}`,
         },
      });
      setSelectedPeriods(response.data.data);
      setPeriods(response.data.data);
   };

   const handlePeriodCheckboxChange = event => {
      const { checked, value } = event.target;
      if (checked) {
         setSelectedPeriods(prev => [...prev, value]);
      } else {
         setSelectedPeriods(prev => prev.filter(prevVal => prevVal !== value));
      }
   };

   useEffect(() => {
      if (selectedAcademicYear) {
         if (selectedPeriods.length > 0) {
            getMonthlyStudentBehaviorsData();
         } else {
            setMonthlyBehaviorResults([]);
         }
      }
   }, [selectedAcademicYear, selectedPeriods]);

   useEffect(() => {
      getAcademicsYearData();
      getPeriodsData();
   }, []);

   return (
      <LoadingWrapper isLoading={isLoading}>
         <Grid container>
            {isStudentFoundInAcademicYear && (
               <Grid item>
                  <Box>
                     <FormGroup>
                        <Grid
                           maxWidth={400}
                           justifyContent={'start'}
                           alignSelf="start"
                           container
                        >
                           {periods.map(period => (
                              <Grid item key={period} xs={6}>
                                 <FormControlLabel
                                    control={
                                       <Checkbox
                                          value={period}
                                          onChange={handlePeriodCheckboxChange}
                                          checked={selectedPeriods.includes(period)}
                                       />
                                    }
                                    label={period}
                                 />
                              </Grid>
                           ))}
                        </Grid>
                     </FormGroup>
                  </Box>
               </Grid>
            )}
            <Grid item>
               <Stack>
                  <br />
                  <FormControl sx={{ width: 200 }} size="small">
                     <InputLabel id="demo-select-small">Year</InputLabel>
                     <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        onChange={e => setSelectedAcademicYear(e.target.value)}
                        value={selectedAcademicYear}
                        label="Age"
                        size="small"
                     >
                        {academicYears &&
                           academicYears.map(academicYear => (
                              <MenuItem
                                 key={academicYear.id}
                                 value={academicYear}
                                 sx={{ alignItems: 'center' }}
                              >
                                 {academicYear.title}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
                  <br />
                  <Typography variant="body1">
                     <strong>Class:</strong>
                     {student && student.mClass.class_name}
                  </Typography>
                  <br />
                  <Typography variant="body1">
                     <strong>Student:</strong> {student && student.student_name}
                  </Typography>
                  {isStudentFoundInAcademicYear && selectedPeriods.length > 0 && (
                     <Fragment>
                        <br />
                        <Button
                           onClick={() => setOpenDialog(true)}
                           fullWidth
                           disableElevation
                           size="small"
                           variant="contained"
                        >
                           <LocalPrintshopIcon />
                           Print
                        </Button>
                     </Fragment>
                  )}
               </Stack>
            </Grid>
         </Grid>
         {!isStudentFoundInAcademicYear && (
            <Box>
               <br />
               <Divider />
               <br />
               <Typography variant="body1">
                  No student record found in that academic year.
               </Typography>
            </Box>
         )}
         {isStudentFoundInAcademicYear && selectedPeriods.length === 0 && (
            <Box>
               <br />
               <Divider />
               <br />
               <Typography variant="body1">Please select some periods!</Typography>
            </Box>
         )}
      </LoadingWrapper>
   );
};
