import MenuIcon from '@mui/icons-material/Menu';

import {
   AppBar,
   Box,
   Button,
   Toolbar,
   Typography,
   Stack,
   SwipeableDrawer,
   IconButton,
   MenuList,
   MenuItem,
} from '@mui/material';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authActions } from '../../store/slices/auth';

export default function ButtonAppBar() {
   const history = useHistory();
   const dispatcher = useDispatch();
   const user = useSelector(state => state.auth.user);
   const [isDrawerOpen, setIsDrawerMode] = useState(false);

   const onSignOut = () => {
      dispatcher(authActions.signOut());
      history.push('/auth/sign-in');
      setIsDrawerMode(false);
   };

   const onSignIn = () => {
      history.push('/auth/sign-in');
      setIsDrawerMode(false);
   };

   return (
      <div>
         {user && (
            <Box sx={{ flexGrow: 1 }}>
               <SwipeableDrawer
                  anchor={'left'}
                  open={isDrawerOpen}
                  onClose={() => setIsDrawerMode(false)}
                  onOpen={() => setIsDrawerMode(true)}
               >
                  <MenuList sx={{ width: 300 }}>
                     {user && (
                        <MenuItem
                           onClick={() => history.push('/behavior/behavior-entrance')}
                           color="inherit"
                        >
                           <Typography fontWeight={600} variant="button">
                              Lectures
                           </Typography>
                        </MenuItem>
                     )}
                     {user && (
                        <MenuItem
                           onClick={() => {
                              history.push('/student');
                              setIsDrawerMode(false);
                           }}
                           color="inherit"
                        >
                           <Typography fontWeight={600} variant="button">
                              Students
                           </Typography>
                        </MenuItem>
                     )}
                     {user && (
                        <MenuItem
                           onClick={() => {
                              history.push('/homework');
                              setIsDrawerMode(false);
                           }}
                           color="inherit"
                        >
                           <Typography fontWeight={600} variant="button">
                              Homeworks
                           </Typography>
                        </MenuItem>
                     )}
                     {user && (
                        <MenuItem
                           onClick={() => {
                              history.push('/note');
                              setIsDrawerMode(false);
                           }}
                           color="inherit"
                        >
                           <Typography fontWeight={600} variant="button">
                              Notes
                           </Typography>
                        </MenuItem>
                     )}
                     {!user && (
                        <MenuItem onClick={onSignIn} color="inherit">
                           <Typography fontWeight={600} variant="button">
                              Sign In
                           </Typography>
                        </MenuItem>
                     )}
                     {user && (
                        <MenuItem onClick={onSignOut} color="inherit">
                           <Typography fontWeight={600} variant="button">
                              Sign Out
                           </Typography>
                        </MenuItem>
                     )}
                  </MenuList>
               </SwipeableDrawer>

               <AppBar position="static">
                  <Toolbar>
                     <Box padding={1} sx={{ display: { xs: 'none', md: 'flex' } }} mx={2}>
                        <img src="/logo-small.png" alt="" />
                     </Box>
                     <Typography
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                        fontWeight={600}
                        marginRight={2}
                        variant="body"
                        component="div"
                     ></Typography>

                     <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
                        {user && (
                           <Button
                              onClick={() => history.push('/behavior/behavior-entrance')}
                              color="inherit"
                           >
                              Lectures
                           </Button>
                        )}

                        {user && (
                           <Button
                              onClick={() => history.push('/student')}
                              color="inherit"
                           >
                              Students
                           </Button>
                        )}

                        {user && (
                           <Button
                              onClick={() => history.push('/homework')}
                              color="inherit"
                           >
                              Homeworks
                           </Button>
                        )}

                        {user && (
                           <Button onClick={() => history.push('/note')} color="inherit">
                              Notes
                           </Button>
                        )}

                        <Box sx={{ flexGrow: 1 }} />

                        {!user && (
                           <Button onClick={onSignIn} color="inherit">
                              Sign In
                           </Button>
                        )}
                        {user && (
                           <Button onClick={onSignOut} color="inherit">
                              Sign Out
                           </Button>
                        )}
                     </Box>

                     <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
                        <Stack
                           sx={{ width: '100%' }}
                           justifyContent={'space-between'}
                           direction={'row'}
                        >
                           <IconButton
                              style={{ color: 'white' }}
                              aria-label="delete"
                              size="large"
                           >
                              <MenuIcon
                                 onClick={() => setIsDrawerMode(true)}
                                 fontSize="inherit"
                              />
                           </IconButton>

                           <Box padding={1}>
                              <img
                                 src="/logo-small.png"
                                 style={{ width: '60px' }}
                                 alt=""
                              />
                           </Box>
                        </Stack>
                     </Box>
                  </Toolbar>
               </AppBar>
            </Box>
         )}
      </div>
   );
}
