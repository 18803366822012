import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Checkbox } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { ColumnCode } from '../../util/code-columns';

const BehaviorRegSwitch = ({ behavior, code, onSwitchChange, color }) => {
   return (
      <div>
         {[ColumnCode.reg, ColumnCode.e, ColumnCode.u].includes(code) && (
            <Checkbox
               size="small"
               sx={{
                  margin: 0,
                  padding: 0,
                  color: red[800],
                  '&.Mui-checked': {
                     color: green[800],
                  },
               }}
               icon={<CloseIcon />}
               checkedIcon={<DoneIcon />}
               checked={behavior[code] > 0}
               onChange={event => onSwitchChange(behavior, code, event.target.checked)}
            />
         )}
         {![ColumnCode.reg, ColumnCode.e, ColumnCode.u].includes(code) && (
            <Checkbox
               size="small"
               sx={{ margin: 0, padding: 0 }}
               color={color}
               checked={behavior[code] > 0}
               onChange={event => onSwitchChange(behavior, code, event.target.checked)}
            />
         )}
      </div>
   );
};

export default BehaviorRegSwitch;
