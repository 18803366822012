import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { TextField, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

const BehaviorHomework = ({ updateHomework, homework }) => {
   const [newHomework, setNewHomework] = useState('');
   const [isInHomeworkEditMode, setIsInHomeworkEditMode] = useState(false);

   useEffect(() => setNewHomework(homework), [homework]);

   return (
      <Stack sx={{ marginTop: 3, width: '100%' }} variant="outlined">
         <TextField
            label="Homework"
            multiline
            fullWidth={true}
            rows={7}
            disabled={!isInHomeworkEditMode}
            onChange={event => setNewHomework(event.target.value)}
            value={newHomework}
            variant="filled"
         />
         <Button
            sx={{ borderRadius: 0, width: '100%' }}
            onClick={() => {
               if (isInHomeworkEditMode) updateHomework(newHomework);
               setIsInHomeworkEditMode(prev => !prev);
            }}
            variant="contained"
            disableElevation
         >
            {isInHomeworkEditMode ? <SaveIcon /> : <EditIcon />}
         </Button>

         {isInHomeworkEditMode && (
            <Button
               sx={{ borderRadius: 0, width: '100%' }}
               onClick={() => {
                  setNewHomework(homework);
                  setIsInHomeworkEditMode(prev => !prev);
               }}
               variant="contained"
               color="error"
               disableElevation
            >
               <DoDisturbIcon />
            </Button>
         )}
      </Stack>
   );
};

export default BehaviorHomework;
