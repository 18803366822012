import { Snackbar, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions } from '../../store/slices/alert';

export default function ButtonAppBar() {
   const dispatch = useDispatch();
   const status = useSelector(state => state.alert.status);
   const message = useSelector(state => state.alert.message);
   const showAlert = useSelector(state => state.alert.showAlert);

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') return;
      dispatch(alertActions.hideAlert());
   };

   return (
      <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleClose}>
         <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
            {message}
         </Alert>
      </Snackbar>
   );
}
