import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
   name: 'alert',
   initialState: {
      status: 'success',
      message: '',
      redirectURL: '',
      showAlert: false,
   },
   reducers: {
      setAlert(state, action) {
         const { status, message, redirectURL } = action.payload;
         state.redirectURL = redirectURL ?? state.redirectURL;
         state.showAlert = true;
         state.status = status;
         state.message = message;
      },
      hideAlert(state) {
         state.showAlert = false;
      },
   },
});

export const alertActions = alertSlice.actions;
export default alertSlice;
